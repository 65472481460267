import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#7466CC',
                secondary: '#7466CC',
                themered: '#D37287',
                themegray: '#3F4A5E',
                themewhite: '#fff',
                themered50: '#F6E3E7',
                themeorange: '#D89A3E',
                themeorange50: '#F7EBD8',
                themepurple: '#7466CC',
                themepurple50: '#D7D7E1',
                themegreen: '#C5DFCF',
                themegreen50: '#ECF4EF',
                themeblue: '#8AC6D0',
                themeblue50: '#E8F4F6',

            },
        },
    }
});