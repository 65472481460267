import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        auth: {
            token: null,
        },
        user: {
            id: -1,
            email: '',
            firstname: '',
            lastname: '',
            employer: "",
            staff: false,
            role: '',
            program: '',
            profilepicture: '',
            functiontitle: '',
            register_date: 0,
            loggedin: false,
            perms: []
        },
        language: 'nl',
    },
    mutations: {
        settoken(state, t) {
            state.auth.token = t
        },
        setuserdata(state, data) {
            state.user = data
        },
        setlanguage(state, data) {
            state.language = data
        },
    },
    actions: {
        settoken(context, t) {
            context.commit('settoken', t)
        },
        setuserdata(context, data) {
            context.commit('setuserdata', data)
        },
        setlanguage(context, data) {
            context.commit('setlanguage', data)
        },
    },
    modules: {},
    getters: {
        getuser(state) {
            return state.user;
        },
        gettoken(state) {
            return state.auth.token;
        },
        getlanguage(state) {
            return state.language;
        },
    }
})