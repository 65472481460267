import axiosInstance from "./api";
import * as cookies from 'js-cookie';
import config from '../config'

const setup = () => {
    axiosInstance.interceptors.request.use(
        (config) => {
            let token = cookies.get("jh_access_token");
            if (token) {
                config.headers["Authorization"] = 'bearer ' + token; // for Spring Boot back-end
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    axiosInstance.interceptors.response.use(
        (res) => {
            return res;
        },
        async(err) => {
            const originalConfig = err.config;
            if (originalConfig.url !== config.url.api + "/v1/auth/step" && originalConfig.url !== config.url.api + "/v1/auth/register" && err.response) {
                // Access Token was expired
                if (err.response.status === 401 && !originalConfig._retry) {
                    originalConfig._retry = true;

                    try {
                        const res = await axiosInstance.post("/v1/auth/token", {
                            refresh_token: cookies.get("jh_refresh_token")
                        });
                        if (res.data.success) {
                            cookies.set("jh_refresh_token", res.data.data.refresh_token, {
                                domain: config.cookiedomain
                            });
                            cookies.set("jh_access_token", res.data.data.access_token, {
                                domain: config.cookiedomain
                            });
                        }
                        // TokenService.

                        return axiosInstance(originalConfig);
                    } catch (_error) {
                        cookies.remove('jh_refresh_token');
                        cookies.remove('jh_access_token');
                        cookies.remove('jh_user');
                        console.log(originalConfig.url);
                        location.replace(config.url.main);
                        return Promise.reject(_error);
                    }
                }
            }
            return Promise.reject(err);


        }

    );
};

export default setup;