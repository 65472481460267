import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        path: '/inloggen',
        component: () =>
            import ( /* webpackChunkName: "werkgevers" */ '../views/auth/__template'),
        children: [{
            path: '',
            component: () =>
                import ('../views/auth/Login'),
        }, ]
    },
    {
        path: '/registreren',
        component: () =>
            import ( /* webpackChunkName: "werkgevers" */ '../views/auth/__template'),
        children: [{
            path: '',
            component: () =>
                import ('../views/auth/Register'),
        }, ]
    }, {
        path: '/registratie-voltooien',
        component: () =>
            import ( /* webpackChunkName: "werkgevers" */ '../views/auth/__template'),
        children: [{
            path: '',
            component: () =>
                import ('../views/auth/RegisterConfirm'),
        }, ]
    },
    {
        path: '/wachtwoord-vergeten',
        component: () =>
            import ( /* webpackChunkName: "werkgevers" */ '../views/auth/__template'),
        children: [{
            path: '',
            component: () =>
                import ('../views/auth/Resetpassword'),
        }, ]
    },
    {
        path: '/wachtwoord-wijzigen',
        component: () =>
            import ( /* webpackChunkName: "werkgevers" */ '../views/auth/__template'),
        children: [{
            path: '',
            component: () =>
                import ('../views/auth/Newpassword'),
        }, ]
    },
    {
        path: '/auth',
        component: () =>
            import ( /* webpackChunkName: "werkgevers" */ '../views/auth/__template'),
        children: [{
            path: '',
            component: () =>
                import ('../views/auth/Main'),
        }, ]
    },
    {
        path: '/',
        component: () =>
            import ( /* webpackChunkName: "main" */ '../views/__template'),
        children: [{
                path: '',
                component: () =>
                    import ( /* webpackChunkName: "main" */ '../views/Dashboard'),
            },
            {
                path: 'home',
                component: () =>
                    import ( /* webpackChunkName: "main" */ '../views/Home'),
            },

            {
                path: '/transformatie-motie',
                component: () =>
                    import ( /* webpackChunkName: "transformationmotions" */ '../views/transformationmotions/__template'),
                children: [{
                    path: ':id',
                    component: () =>
                        import ( /* webpackChunkName: "transformationmotions" */ '../views/transformationmotions/View'),
                }],
            },

            {
                path: '/dialoogformulier-lijnen',
                component: () =>
                    import ( /* webpackChunkName: "dialogueformlines" */ '../views/dialogueformlines/__template'),
                children: [{
                        path: ':id',
                        component: () =>
                            import ( /* webpackChunkName: "dialogueformlines" */ '../views/dialogueformlines/View'),
                    },

                ],
            },

            {
                path: '/dialoogformulier-programma',
                component: () =>
                    import ( /* webpackChunkName: "dialogueformlines" */ '../views/dialogueformprogram/__template'),
                children: [{
                        path: ':id',
                        component: () =>
                            import ( /* webpackChunkName: "dialogueformlines" */ '../views/dialogueformprogram/View'),
                    },

                ],
            },

            {
                path: '/bevindingen',
                component: () =>
                    import ( /* webpackChunkName: "findings" */ '../views/findings/__template'),
                children: [{
                        path: ':id',
                        component: () =>
                            import ( /* webpackChunkName: "findings" */ '../views/findings/View'),
                    },

                ],
            },

            {
                path: '/project-uitnodiging/:id/:subid',
                component: () =>
                    import ( /* webpackChunkName: "projects" */ '../views/project/Invite'),
            },

            {
                path: '/programma-uitnodiging/:id/:subid',
                component: () =>
                    import ( /* webpackChunkName: "projects" */ '../views/program/Invite'),
            },

            {
                path: '/project',
                component: () =>
                    import ( /* webpackChunkName: "projects" */ '../views/project/__template'),
                children: [{
                        path: ':id',
                        component: () =>
                            import ( /* webpackChunkName: "projects" */ '../views/project/Overview'),
                    },
                    {
                        path: ':id/tellen-en-vertellen',
                        component: () =>
                            import ( /* webpackChunkName: "countingandtellings" */ '../views/project/countingandtellings/Overview'),
                    },
                    {
                        path: ':id/tellen-en-vertellen/:subid',
                        component: () =>
                            import ( /* webpackChunkName: "countingandtellings" */ '../views/project/countingandtellings/View'),
                    },
                    {
                        path: ':id/monitoringsplan',
                        component: () =>
                            import ( /* webpackChunkName: "monitoringsplan" */ '../views/project/monitoringsplan/Overview'),

                    },
                    {
                        path: ':id/monitoringsplan/:subid',
                        component: () =>
                            import ( /* webpackChunkName: "monitoringsplan" */ '../views/project/monitoringsplan/View'),
                    },
                    {
                        path: ':id/monitoringsplan/:subid/bijlage/:bijlageid',
                        component: () =>
                            import ( /* webpackChunkName: "monitoringsplan" */ '../views/project/monitoringsplan/Appendix'),
                    },
                    {
                        path: ':id/transformatie-canvas',
                        component: () =>
                            import ( /* webpackChunkName: "monitoringsplan" */ '../views/project/transformationcanvas/Overview'),
                    },
                    {
                        path: ':id/transformatie-canvas/:subid',
                        component: () =>
                            import ( /* webpackChunkName: "monitoringsplan" */ '../views/project/transformationcanvas/View'),
                    },
                    {
                        path: ':id/transformatie-canvas/:subid/:tabid',
                        component: () =>
                            import ( /* webpackChunkName: "monitoringsplan" */ '../views/project/transformationcanvas/View'),
                    }
                ],
            },
            {
                path: 'mijn-account',
                component: () =>
                    import ( /* webpackChunkName: "notifications" */ '../views/Account'),
            },

            {
                path: '/:catchAll(.*)',
                component: () =>
                    import ( /* webpackChunkName: "404" */ '../views/404')
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router