<template>
  <router-view />
</template>

<script>
import * as cookies from "js-cookie";
import api from "./services/api";
import config from '@/config';
import "vue-toastification/dist/index.css";

export default {
  name: "App",
  components: {},
  data: () => ({
    ready: false,

  }),
  beforeMount() {

    if (!location.pathname.startsWith('/inloggen') && !location.pathname.startsWith('/registreren') && !location.pathname.startsWith('/registratie-voltooien') && !location.pathname.startsWith('/wachtwoord-vergeten') && !location.pathname.startsWith('/wachtwoord-wijzigen') && !location.pathname.startsWith('/auth')) {
      if (cookies.get("jh_refresh_token")) {
        api.get(`/v1/users/0/info`).then((res) => {
          res.data.loggedin = true;
          this.$store.dispatch('setuserdata', res.data)
          this.ready = true;
        }).catch(err => {
          if (err.response.status === 401) {
            cookies.remove('jh_access_token', { domain: config.cookiedomain, path: '/' });
            cookies.remove('jh_refresh_token', { domain: config.cookiedomain, path: '/' });
            cookies.remove('jh_user', { domain: config.cookiedomain, path: '/' });
            location.replace(`${config.url.login}?r=${encodeURI(location.href)}`)
          }
          else {
            // todo: error page
          }
        })
      } else {
        this.$router.push('/auth')
      }
    } else {
      this.ready = true;
    }
  }
};


Object.defineProperty(String.prototype, 'capitalize', {
  value: function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false
});


Object.defineProperty(String.prototype, 'getrole', {
  value: function () {
    switch (this) {
      case "OG":
        return "Organisator";
      case "PM":
        return "Procesmanager/Clustermanager/Lijnmanager";
      case "PU":
        return "Projectleider";
    }
  },
  enumerable: false
});
</script>
<style>
:root {
  --primary: #7466cc;
  --secondary: #7466cc;
  --themered: #d37287;
  --themered50: #f6e3e7;
  --themeorange: #d89a3e;
  --themeorange50: #f7ebd8;
  --themepurple: #7466cc;
  --themepurple50: #d7d7e1;
  --themegreen: #c5dfcf;
  --themegreen50: #ecf4ef;
  --themeblue: #8ac6d0;
  --themeblue50: #e8f4f6;
  --themelightgray: #3F4A5ECC;

}

body {
  color: #3f4a5e;
}

h1,
.h1 {
  font-size: 32px;
  line-height: 1.4;
  font-weight: 700;
  font-family: "Roboto";
  color: #3f4a5e;
}

h2,
.h2 {
  font-size: 24px;
  line-height: 1.4;
  font-weight: 700;
  font-family: "Roboto";
  color: #3f4a5e;
}

h3,
.h3 {
  font-size: 22px;
  line-height: 1.4;
  font-weight: 700;
  font-family: "Roboto";
  color: #3f4a5e;
}

h4,
.h4 {
  font-size: 20px;
  line-height: 1.4;
  font-weight: 700;
  font-family: "Roboto";
  color: #3f4a5e;
}

h5,
.h5 {
  font-size: 18px;
  line-height: 1.4;
  font-weight: 700;
  font-family: "Roboto";
  color: #3f4a5e;
}

h6,
.h6 {
  font-size: 16px;
  line-height: 1.4;
  font-weight: 700;
  font-family: "Roboto";
  color: #3f4a5e;
}

p,
.p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  font-family: "Roboto";
  color: #3f4a5e;
}

.v-btn.btn {
  font-size: 16px !important;
  text-transform: capitalize !important;
  padding: 16px 24px !important;
  letter-spacing: unset;
  font-weight: bold;
  border-radius: 4px !important;
  height: unset !important;
  box-shadow: 0px 2px 2px #3f4a5e16 !important;
}

.container-large {
  width: 1800px;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.text-white,
.text-white .v-btn__content {
  color: #fff;
}

.text-purple {
  color: var(--themepurple);
}

.text-error {
  color: var(--themered);
}

.text-warning {
  color: var(--themeorange);
}

.fs-20 {
  font-size: 20px !important
}


/* Dialogs */

.theme-popup {
  border: 1px solid var(--themepurple);
  background: #E8E7EE;
  padding: 30px;
  border-radius: 16px !important;
  width: 700px !important;
  max-width: 100% !important;
}

.theme-fullsceen-dialog{
   border: 1px solid var(--themepurple);
  background: #E8E7EE;
  padding: 30px;
  max-width: 100% !important;
}

/* End dialogs */

label {
  font-weight: bold;
  margin-bottom: 5px;
  color: #3F4A5E;
}

.theme-input .v-input__slot {
  box-shadow: none !important;
}

.text-primary {
  color: var(--primary) !important
}

.text-gray {
  color: var(--themelightgray) !important
}

.sticky-top {
  position: sticky;
  top: 0px;
}

/* Project /canvas sidebar */
.sidebar-drawer {
  height: 100%;
  position: relative;
}

.sidebar-drawer .bottom-text {
  position: absolute !important;
  bottom: 20px !important;
  font-size: 16px;
  color: #3f4a5e80;
}

.theme--light.v-application {
  background: rgba(255, 255, 255, 0.96) !important;
  border-radius: 10px !important;
}

.v-list-item {
  font-size: 20px;
}

.v-list-item small {
  font-size: 14px;
}

.v-list-item.v-item--active {
  font-weight: bold;
}

.main-top-card-border {
  border-top: 5px solid var(--themeblue50) !important;
}

.text-center {
  text-align: center;
}
</style>
